import {
    EQUIPMENTS_LIST,
    EQUIPMENTS_SHEET,
    EQUIPMENTS_DELETE_REQUEST_FORM    
} from '../constants'

export default [   
    {
        path: `${EQUIPMENTS_LIST}`,
        name: 'list_equipments',
        component: () => import('@/views/equipments/list/listView.vue'),
        meta: {
            pageTitle: 'Listado de Equipos',
            permission: 'see_equipments_gmao',
            breadcrumb: [
                {
                    text: 'Listado de Equipos',
                    active: true,
                    permission: 'see_equipments_gmao',
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_SHEET}/:id`,
        name: 'sheet_equipment',
        component: () => import('@/views/equipments/sheet/sheetView.vue'),
        meta: {
            pageTitle: 'Ficha de Equipo',
            permission: 'see_equipment_sheet_gmao',
            breadcrumb: [
                {
                    text: 'Ficha de Equipo',
                    active: true,
                    permission: 'see_equipment_sheet_gmao',
                },
            ],
        },
    },
    {
        path: `${EQUIPMENTS_DELETE_REQUEST_FORM}/:id`,
        name: 'form_equipment_delete_request',
        component: () => import('@/views/equipments/form/deleteRequestFormView.vue'),
        meta: {
            pageTitle: 'Crear solicitud de baja de Equipo',
            permission: 'create_equipment_delete_request',
            breadcrumb: [
                {
                    text: 'Crear solicitud de baja de Equipo',
                    active: true,
                    permission: 'create_equipment_delete_request',
                },
            ],
        },
    },
]
