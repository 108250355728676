import axios from '@/resources/libs/axios'

export default {
    listClient(equipment_id) {
        return axios.get(`/gmao/tags/list-client/${equipment_id}`)
    },
    getTagsFromEquipment(equipment_id){
        return axios.get(`/gmao/tags/get-tags-equipment/${equipment_id}`)
    },
    getTagsClient(client_id){
        return axios.get(`/gmao/tags/list-client-tags/${client_id}`)
    },
    listTagsByEquipmentsList(){
        return axios.get(`/gmao/tags/list-tags-by-equipments-list`)
    }
}