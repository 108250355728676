export const getDefaultState = () => {
    return {
        equipments: [],

        equipment: {
            id: null,
            serial_number: null,
            description: null,
            equipment_photo: null,
            equipment_photo_file: null,
            plate_photo: null,
            plate_photo_file: null,
            tag_id: null,
            client_id: null,
            uncatalogued: null,

            client: {
                id: null,
                name: null,
            },
            equipment_certification: [],
            equipment_certification_erased: [],
            equipment_delivery_note: [],
            equipment_delivery_note_erased: [],
            equipment_predictive_report: [],
            equipment_predictive_report_erased: [],
            equipment_repair_report: [],
            equipment_repair_report_erased: [],
            equipment_tecnical_documentation: [],
            equipment_tecnical_documentation_erased: [],
        },

        validation: {
            serial_number: null,
            description: null,
            equipment_photo: null,
            equipment_photo_file: null,
            plate_photo: null,
            plate_photo_file: null,
            client_id: null,
            tag_id: null,
            uncatalogued: null,
        },
        search: {
            // wingest_id: null,
            // name: null,
            // email: null,
            // address_1: null,
            // cif: null,
            // phone_1: null,

            serial_number: null,
            location: null,
            description: null,
            client_id: null,
            tag_id: null,
        }
    }
}

export default getDefaultState()