import api from "@/router/api"

export default {
    create({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.create(payload)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({
                        message: 'Error al crear la intervencion'
                    })
                })
        })
    },
    update({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.update(payload)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({
                        message: 'Error al actualizar la intervencion'
                    })
                })
        })
    },
    listByEquipment({ commit }, payload) {
        return new Promise((resolve, reject) => {

            api.interventions.listByEquipment(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_INTERVENTIONS', data.data)
                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Error al obtener los equipos'
                    })
                })
        })
    },
    getIntervention({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.find(payload.id, payload)
                .then(response => {
                    commit('SET_INTERVENTION', response.data.data)
                    resolve(response.data.data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener la intervencion',
                    })
                })
        })
    },
    close({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.close(payload)
                .then(response => {
                    commit('SET_INTERVENTION', response.data.data)
                    resolve(response.data.data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({
                        message: 'Error al crear la intervencion'
                    })
                })
        })
    },
    delete({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.interventions.delete(payload.id)
                .then(response => {
                    const { data } = response.data
                    resolve(data)
                })
                .catch(error => {
                    const { data, status } = error.response
                    const { message: title } = data

                    if (status === 422) {
                        commit('SET_VALIDATION_ERRORS', data.errors)
                    }
                    reject({
                        message: 'Error al crear la intervencion'
                    })
                })
        })
    },
}
