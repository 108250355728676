export const getDefaultState = () => {
    return {
        userRegisterRequest: {
            company: null,
            name: null,
            job: null,
            email: null,
            phone: null,
        },

        validation: {
            company: null,
            name: null,
            job: null,
            email: null,
            phone: null,
        },
    }
}

export default getDefaultState()