import EventEmitter from 'events'

import axios from '@/resources/libs/axios'
import {LOGIN} from '@/router/web/constants'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const TOKEN_EXPIRY_KEY = 'tokenExpiry'
export const USER_INFO_KEY = 'userData'

class AuthService extends EventEmitter {
    setBearerCode(accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    }

    setExpiry(expiration) {
        localStorage.setItem(TOKEN_EXPIRY_KEY, expiration)
    }

    setUser(user) {
        localStorage.setItem(USER_INFO_KEY, user)
    }

    setAccessToken(token) {
        localStorage.setItem(ACCESS_TOKEN_KEY, token)
    }

    user() {
        return JSON.parse(localStorage.getItem(USER_INFO_KEY))
    }

    expiry() {
        return localStorage.getItem(TOKEN_EXPIRY_KEY)
    }

    accessToken() {
        return localStorage.getItem(ACCESS_TOKEN_KEY)
    }

    logOut() {
        axios.defaults.headers.common.Authorization = null

        localStorage.removeItem(ACCESS_TOKEN_KEY)
        localStorage.removeItem(TOKEN_EXPIRY_KEY)
        localStorage.removeItem(USER_INFO_KEY)
    }

    redirectToLogin() {
        window.location.href = LOGIN
    }

    isAuthenticated() {
        const currentDate = new Date(Date.now())
        // eslint-disable-next-line radix
        const tokenExpiry = new Date(parseInt(this.expiry()))
        const accessToken = this.accessToken()
        return (currentDate < tokenExpiry) && accessToken
    }

    can(permission) {
        const user = JSON.parse(localStorage.getItem(USER_INFO_KEY))

        return user.permissions.indexOf(permission) !== -1
    }
}

export default new AuthService()
