import state from './interventionState'
import actions from './interventionStatesActions'
import mutations from './interventionStatesMutations'
import getters from './interventionStatesGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
