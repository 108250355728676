import axios from '@/resources/libs/axios'

export default {
	listByWorkCenter(data) {
		return axios.get(`/sat/clients/list/workcenter`, {params: data})
	},
	listByEquipmentsList() {
		return axios.get(`/gmao/clients/list-clients-by-equipments-list`)
	},
	
}