import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app/app'
import user from './user/user'
import equipment from './equipment/equipment'
import userRegisterRequest from './userRegisterRequest/userRegisterRequest'
import tag from './tag/tag'
import intervention from './intervention/intervention'
import interventionStates from './interventionStates/interventionStates'
import equipmentDeleteRequest from './equipmentDeleteRequest/equipmentDeleteRequest'
import client from './client/client'

import notification from './notification/notification'
import auth from './auth/auth'
import appConfig from './app-config/appConfig'
import verticalMenu from './vertical-menu/verticalMenu'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import state from './state'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        auth,
        user,
        notification,
        appConfig,
        verticalMenu,
        equipment,
        userRegisterRequest,
        tag,
        intervention,
        interventionStates,
        equipmentDeleteRequest,
        client,
    },
    state,
    actions,
    getters,
    mutations,
    strict: process.env.DEV,
})