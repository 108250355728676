import {
    USER_REGISTER_REQUEST,
} from '../constants'

export default [   
    {
        path: `${USER_REGISTER_REQUEST}`,
        name: 'user_register_requests',
        component: () => import('@/views/userRegisterRequests/form/formView.vue'),
        meta: {
            pageTitle: 'Crear peticion de registro',
            breadcrumb: [
                {
                    
                    text: 'Crear peticion de registro',
                    active: true,
                },
            ],
            layout: 'full',
        },
    },
]
