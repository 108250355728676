import auth from "./modules/auth"
import equipments from "./modules/equipments"
import userRegisterRequest from "./modules/userRegisterRequest"
import tags from "./modules/tags"
import interventions from "./modules/interventions"
import interventionsStates from "./modules/interventionsStates"
import equipmentDeleteRequest from "./modules/equipmentDeleteRequest"
import clients from "./modules/clients"

export default {
    auth,
    equipments,
    userRegisterRequest,
    tags,
    interventions,
    interventionsStates,
    equipmentDeleteRequest,
    clients,
}