import Vue from 'vue'
import {baseUrl} from "@/constants/app"

// axios
import axios from 'axios'

const axiosIns = axios.create({
    baseURL: baseUrl + 'api/',
    // You can add your headers here
    // ================================
    // baseURL: 'https://some-domain.com/api/',
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
