import state from './tagState'
import actions from './tagActions'
import mutations from './tagMutations'
import getters from './tagGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
