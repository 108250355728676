import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./interventionState"

export default {
    updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
    SET_STATES(state, payload) {
		state.states = payload
	},
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation[key] = '* '+payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation) {
            state.validation[key] = null
        }
    },
}
