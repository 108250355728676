import api from "@/router/api"

export default {
    listClient({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.tags.listClient(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TAGS', data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los tags'
                    })
                })
        })
    },
    listClientTags({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.tags.getTagsClient(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TAGS', data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los tags'
                    })
                })
        })
    },
    listTagsByEquipmentsList({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.tags.listTagsByEquipmentsList(payload)
                .then(response => {
                    const { data } = response.data
                    commit('SET_TAGS', data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los tags'
                    })
                })
        })
    },


}
