export const getDefaultState = () => {
    return {
        equipmentDeleteRequest: {
            concept: null,
            origin_tag_id: null,
            destinatary_tag_id: null,
            description: null,
        },

        validation: {
            concept: null,
            origin_tag_id: null,
            destinatary_tag_id: null,
            description: null,
        },
    }
}

export default getDefaultState()