import {
    INTERVENTIONS_CREATE,
    INTERVENTIONS_LIST_EQUIPMENT,
    INTERVENTIONS_SHEET,
    INTERVENTIONS_EDIT,
    INTERVENTIONS_MANAGE,
} from '../constants'

export default [   
    {
        path: `${INTERVENTIONS_CREATE}/:id`,
        name: 'create_interventions',
        component: () => import('@/views/interventions/form/formView.vue'),
        meta: {
            pageTitle: 'Crear intervención',
            permission: 'create_intervention',
            breadcrumb: [
                {
                    text: 'Crear intervención',
                    active: true,
                    permission: 'create_intervention',
                },
            ],
        },
    },
    {
        path: `${INTERVENTIONS_LIST_EQUIPMENT}/:id/:stateOpened`,
        name: 'list_interventions_from_equipment',
        component: () => import('@/views/interventions/list/listView.vue'),
        meta: {
            pageTitle: 'Listado intervenciones',
            permission: 'list_interventions_equipment',
            breadcrumb: [
                {
                    text: 'Listado intervenciones',
                    active: true,
                    permission: 'list_interventions_equipment',
                },
            ],
        },
    }, 
    {
        path: `${INTERVENTIONS_SHEET}/:id`,
        name: 'sheet_intervention',
        component: () => import('@/views/interventions/sheet/sheetView.vue'),
        meta: {
            pageTitle: 'Ficha de intervención',
            permission: 'see_intervention',
            breadcrumb: [
                {
                    text: 'Ficha de intervención',
                    active: true,
                    permission: 'see_intervention',
                },
            ],
        },
    },  
    {
        path: `${INTERVENTIONS_EDIT}/:id`,
        name: 'edit_intervention',
        component: () => import('@/views/interventions/form/editView.vue'),
        meta: {
            pageTitle: 'Editar de intervención',
            permission: 'edit_intervention',
            breadcrumb: [
                {
                    text: 'Editar de intervención',
                    active: true,
                    permission: 'edit_intervention',
                },
            ],
        },
    },  
    {
        path: `${INTERVENTIONS_MANAGE}/:id`,
        name: 'manage_intervention',
        component: () => import('@/views/interventions/form/manageView.vue'),
        meta: {
            pageTitle: 'Gestionar intervención',
            permission: 'manage_intervention',
            breadcrumb: [
                {
                    text: 'Gestionar intervención',
                    active: true,
                    permission: 'manage_intervention',
                },
            ],
        },
    },  
    
    

]