import api from "@/router/api"

export default {
    searchEquipments({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.search(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_EQUIPMENTS', data.data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los equipos'
                    })
                })
        })
    },
    getEquipment({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.find(payload.id, payload)
                .then(response => {
                    commit('SET_EQUIPMENT', response.data.data)
                    commit('SET_EQUIPMENT_TECNICAL_DOCUMENTATION', response.data.data.equipment_tecnical_documentation)
                    commit('SET_EQUIPMENT_CERTIFICATION', response.data.data.equipment_certification)
                    commit('SET_EQUIPMENT_DELIVERY_NOTES', response.data.data.equipment_delivery_note)
                    commit('SET_EQUIPMENT_PREDICTIVE_REPORT', response.data.data.equipment_predictive_report)
                    commit('SET_EQUIPMENT_REPAIR_REPORT', response.data.data.equipment_repair_report)

                    resolve(response.data.data)
                })
                .catch(error => {
                    console.log(error);
                    reject({
                        message: 'Ocurrió un problema al obtener el equipo',
                    })
                })
        })
    },
    listDocumentsInternalFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.listDocumentsInternalFolder(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Error al obtener los registros.',
                    })
                })
        })
    },
    listDocumentsClientFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.listDocumentsClientFolder(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Error al obtener los registros.',
                    })
                })
        })
    },
    saveDocumentsInternalFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.saveDocumentsInternalFolder(payload.data)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    createDocumentsInternalFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.createDocumentsInternalFolder(payload.data)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    saveDocumentsClientFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.saveDocumentsClientFolder(payload.data)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    deleteDocumentsInternalFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.deleteDocumentsInternalFolder(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    getQrImage({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.getQrImage(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    createDocumentsClientFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.createDocumentsClientFolder(payload.data)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
    deleteDocumentsClientFolder({commit}, payload){
        return new Promise((resolve, reject) => {
            api.equipments.deleteDocumentsClientFolder(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    const { data, status} = error.response
                    const { message: title } = data

                    reject({title, status})
                })
        })
    },
}
