import api from "@/router/api"

export default {
    getStates({commit}, payload){
        return new Promise((resolve, reject) => {
            api.interventionsStates.list()
                .then(response => {   
                    
                    const {data} = response.data
                    commit('SET_STATES', data.data)
                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los estados',
                    })
                })
        })
    },
}
