export const getDefaultState = () => {
    return {
        tags: [],
        equipments: [],
        validation: {
            equipment_id: null,
            urgent: null,
            tag_id: null,
            description: null,
        },
    }
}

export default getDefaultState()