export const HOME = '/'
export const DASHBOARD = '/dashboard'

export const LOGIN = '/login'
export const REGISTER = '/register'
export const FORGOT_PASSWORD = '/forgot-password'
export const PAGE_404 = '/error-404'
export const NOT_AUTHORIZED = '/pages/miscellaneous/not-authorized'
export const UNDER_MAINTENANCE = '/pages/miscellaneous/under-maintenance'
export const ERROR = '/pages/miscellaneous/error'

export const EQUIPMENTS_LIST = '/equipamientos'
export const EQUIPMENTS_SHEET = '/equipamientos/ver'
export const EQUIPMENTS_DELETE_REQUEST_FORM = '/equipamientos/crear-peticion-baja'

export const USER_REGISTER_REQUEST = '/registro'

export const INTERVENTIONS_CREATE = '/intervenciones/crear'
export const INTERVENTIONS_LIST_EQUIPMENT = '/intervenciones/ver/equipo'
export const INTERVENTIONS_SHEET = '/intervenciones/ver'
export const INTERVENTIONS_EDIT = '/intervenciones/editar'
export const INTERVENTIONS_MANAGE = '/intervenciones/gestionar'
