import api from "@/router/api"

export default {
	create({ commit }, payload) {
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.userRegisterRequest.create(payload)
				.then(response => {
					const { data } = response.data

					resolve(data)
				})
				.catch(error => {

					const { data, status } = error.response
					const { message: title } = data

					console.log(data.errors);

					if (status === 422) {
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({ title, status })
				})
		})
	},
}
