import authService from "@/services/auth/authService"

const mutations = {
    // Updates user info in state and localstorage
    UPDATE_USER_INFO(state, payload) {
        const userInfo = authService.user() || state.AppActiveUser
        for (const property of Object.keys(payload)) {

            if (payload[property] !== null) {
                // If some of user property is null - user default property defined in state.AppActiveUser
                state.AppActiveUser[property] = payload[property]

                // Update key in localStorage
                userInfo[property] = payload[property]
            }
        }

        authService.setUser(JSON.stringify(userInfo))
    },
    SET_PAGE_TITLE(state, payload) {
        state.pageTitle = payload
    },
}

export default mutations