import api from "@/router/api"

export default {
    listByUser({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.notifications.listByUser(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_NOTIFICATIONS', data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las notificaciones'
                    })
                })
        })
    },
    markAsRead({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.notifications.markAsRead(payload)
                .then(response => {
                    const {data} = response.data
                    commit('SET_NOTIFICATIONS', data)
                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener las notificaciones'
                    })
                })
        })
    }
}