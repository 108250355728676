import api from "@/router/api"

export default {
	getClientsByWorkCenter({commit}, payload){
		return new Promise((resolve, reject) => {
			api.clients.listByWorkCenter(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},
	listByEquipmentsList({commit}, payload){
		return new Promise((resolve, reject) => {
			api.clients.listByEquipmentsList(payload)
				.then(response => {
					const { data } = response.data
                    commit('SET_CLIENTS', data)
                    resolve(data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},

	
}
