import state from './interventionState'
import actions from './interventionActions'
import mutations from './interventionMutations'
import getters from './interventionGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
