import axios from '@/resources/libs/axios'

export default {
    search(data) {
        return axios.get(`/gmao/equipments/list`, {params: data})
    },
    find(id, data) {
        return axios.get(`/gmao/equipments/find/${id}`, {params: data})
    },
    listDocumentsInternalFolder(data) {
        return axios.get(`/sat/equipments/list/documents/internal/${data.id}`, {params: data})
    },
    saveDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/save', data)
    },
    createDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/create', data)
    },
    deleteDocumentsInternalFolder(data) {
        return axios.post('/sat/equipments/documents/internal/delete', data)
    },
    getQrImage(id) {
        return axios.get(`/gmao/equipments/get-qr-image/${id}`)
    },
    listDocumentsClientFolder(data) {
        return axios.get(`/sat/equipments/list/documents/client/${data.id}`, {params: data})
    },
    saveDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/save', data)
    },
    createDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/create', data)
    },
    deleteDocumentsClientFolder(data) {
        return axios.post('/sat/equipments/documents/client/delete', data)
    },
}
