import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn } from '@/services/auth/utils'
import {DASHBOARD, EQUIPMENTS_SHEET, LOGIN, USER_REGISTER_REQUEST} from './constants'
import ToastificationContent from "@core/components/toastification/ToastificationContent"
import store from '@/store'

import pages from './modules/pages'
import equipments from './modules/equipments'
import userRegisterRequest from './modules/userRegisterRequest'
import interventions from './modules/interventions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {	path: '/', redirect: {name: 'dashboard'} },
        {
            path: DASHBOARD,
            name: 'dashboard',
            component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
        },
        ...pages,
        ...equipments,
        ...userRegisterRequest,
        ...interventions,
        {
            path: '*',
            redirect: 'error-404',
        },
        {
            path: `/sistema`,
            name: 'sistema',
            component: () => import('@/views/system.vue'),
            meta: {
                pageTitle: 'Sistema',
                permission: 'system',
                breadcrumb: [
                    {
                        text: 'Sistema',
                        active: true,
                        permission: 'system',
                    },
                ],
            },
        },
    ],
})

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn()

    if (!isLoggedIn && (to.name != "auth-login" && to.fullPath != LOGIN && to.fullPath != USER_REGISTER_REQUEST )) {
        if (to.name=="sheet_equipment"){
            return next({name: 'auth-login', params: { id: to.params.id} })
        }else{
            return next({name: 'auth-login' })
        }
    }

    if (to.meta.permission) {
        if (!store.getters.can(to.meta.permission)) {
            Vue.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `No tienes permisos para acceder a esa página.`,
                    icon: 'XSquareIcon',
                    variant: 'danger',
                },
            })

            router.push({path: DASHBOARD})
        }
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
