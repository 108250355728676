import axios from '@/resources/libs/axios'

export default {
    create(data) {
        return axios.post(`/gmao/interventions/create`, data)
    },
    update(data) {
        return axios.put(`/gmao/interventions/update`, data)
    },
    listByEquipment(data) {
        return axios.get(`/gmao/interventions/list-by-equipment/${data.equipment_id}`, { params: data })
    },
    find(id, data) {
        return axios.get(`/gmao/interventions/find/${id}`, {params: data})
    },
    close(data) {
        return axios.post(`/gmao/interventions/close`, data)
    },
    delete(id) {
        return axios.delete(`/gmao/interventions/delete/${id}`)
    },
}