import state from './equipmentDeleteRequestState'
import actions from './equipmentDeleteRequestActions'
import mutations from './equipmentDeleteRequestMutations'
import getters from './equipmentDeleteRequestGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
