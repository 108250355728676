import state from './appState'
import actions from './appActions'
import mutations from './appMutations'
import getters from './appGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}