import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./tagState"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_TAG(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            if (key === 'active_tag')
                state.tag['tag_id'] = value[0].id
            if (key === 'uncatalogued')
                state.tag['uncatalogued'] = !!value
             else
                state.tag[key] = value
        })
    },
    SET_TAGS(state, payload) {
        state.tags = payload
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation[key] = '* '+payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation) {
            state.validation[key] = null
        }
    },
}
